<template>
  <div id="reader__workspace__analysis--sentence__toolbar" class="px6 pt6 no-outline">
    <div class="flex-group-start flex-wrap w-full">
      <div>
        <button @click="unselectAll"
                @mouseover="setInfobarText('Alle deaktivieren')"
                @mouseleave="setInfobarText('')"
                class="p-4 sentence-viewer__panel--button flex-items-center light-grey hover-olive mx-4 h-16">
          <i class="fas fa-power-off text-3xl"></i></button>
      </div>
      <!--      TOOL-->
      <!--Eventhandler im Container angewandt, weil sie nicht funktionieren, wenn die Buttons selbst disabled sind.-->
      <div v-for="toolcomponent in this.tools"
           @mouseover="setInfobarText(toolcomponent.btn_infobar_html)"
           @mouseleave="setInfobarText('')">

        <button
            @click="select(toolcomponent)"


            :disabled="toolcomponent.btn_disabled_if"
            v-show="!toolcomponent.btn_hidden_if"
            :class="[
                toolcomponent.name,
                {'olive reader-sentence-viewer_toolbar-button--active bg-white':toolcomponent.active,
                 'img-grayscale hover-img-color': !toolcomponent.active,
                 'alpha-feature':toolcomponent.$el.classList && toolcomponent.$el.classList.contains('alpha-feature'),
                 'hidden':toolcomponent.$el.classList && toolcomponent.$el.classList.contains('hidden'),
                }
                 ]"
            class="p-4 sentence-viewer__panel--button  flex-items-center light-grey hover-olive disabled:opacity-50 mr2">
          <img :src=toolcomponent.btn_img_src width="30" class="mr2" alt="">
          <span class="inline-block text-xl ml1" v-html="toolcomponent.btn_html"></span>
        </button>
      </div>
    </div>
    <div class="reader__workspace__analysis--sentence__toolbar-container flex-group-start flex-wrap ">

      <!--STEPS-->
      <reader-sentence-viewer-tool-steps
          ref="tool_satzschritte"
          :key="2"
          name="SentenceViewerSteps" v-if="this.$parent.hasSteps" :steps="this.$parent.Steps"
          class="reader__workspace__analysis--sentence__toolbar--item flex-items-center w-full  p-4 "
      ></reader-sentence-viewer-tool-steps>
      <!--COLOR LINKS-->
      <reader-sentence-viewer-tool-color-links
          ref="tool_farben"
          :key="1" v-if="this.$parent.hasFarben"
          name="sentence-tool-colorlinks"
          class="reader__workspace__analysis--sentence__toolbar--item flex-items-center w-full   p-4"
      ></reader-sentence-viewer-tool-color-links>
      <!--HIGHLIGHTS / FARBHINTERLEGUNGEN-->
      <reader-sentence-viewer-tool-highlights
          ref="tool_highlights"
          :key="6" v-if="this.$parent.hasHighlights"
          name="sentence-tool-highlights"
          class="reader__workspace__analysis--sentence__toolbar--item flex-items-center w-full   p-4"
      ></reader-sentence-viewer-tool-highlights>
      <!--SUBJEKTE UND PRAEDIKATE-->
      <reader-sentence-viewer-tool-subjpred
          ref="tool_subjpred"
          :key="3"
          v-if="this.$parent.hasSubjPred"
          name="sentence-tool-subjpred"
          class="reader__workspace__analysis--sentence__toolbar--item flex-items-center w-full  p-4 "
      ></reader-sentence-viewer-tool-subjpred>

      <reader-sentence-viewer-tool-answers
          ref="tool_answers"
          name="SentenceViewerAnswers" :key="4"></reader-sentence-viewer-tool-answers>
      <!--SYNTAX-->
      <reader-sentence-viewer-tool-syntax
          ref="tool_syntax"
          v-subscription-feature v-if="this.$parent.$parent.data.config.syntaxanalyse === true" :key="5" name="sentence-tool-syntax"
          class="reader__workspace__analysis--sentence__toolbar--item flex-items-center w-full   "
      ></reader-sentence-viewer-tool-syntax>
      <!--GRAMMATICUS (HERMENEUS AI)-->
      <reader-sentence-viewer-tool-satzhilfe
          ref="tool_grammaticus"
          v-alpha-feature :key="7" name="sentence-tool-grammaticus"
          class="reader__workspace__analysis--sentence__toolbar--item flex-items-center w-full  "
      ></reader-sentence-viewer-tool-satzhilfe>
    </div>
  </div>

</template>

<script>
import ReaderSentenceViewerToolSteps from "./ReaderSentenceViewerToolSteps.vue"
import ReaderSentenceViewerToolFarben from "./ReaderSentenceViewerToolFarben.vue"
import ReaderSentenceViewerToolSubjPred from "./ReaderSentenceViewerToolSubjPred.vue"
import ReaderSentenceViewerToolSyntax from "./ReaderSentenceViewerToolSyntax.vue"
import {EventBus} from "../../../../hermeneus.eventbus/eventbus";
import ReaderSentenceViewerToolAnswers from "./ReaderSentenceViewerToolAnswers.vue"
import ReaderSentenceViewerToolHighlights from "./ReaderSentenceViewerToolHighlights.vue";
import ReaderSentenceViewerToolSatzhilfe from "./ReaderSentenceViewerToolSatzhilfe.vue";

export default {
  name: "reader-sentence-viewer-toolbar",
  components: {
    'reader-sentence-viewer-tool-color-links': ReaderSentenceViewerToolFarben,
    'reader-sentence-viewer-tool-highlights': ReaderSentenceViewerToolHighlights,
    'reader-sentence-viewer-tool-steps': ReaderSentenceViewerToolSteps,
    'reader-sentence-viewer-tool-subjpred': ReaderSentenceViewerToolSubjPred,
    'reader-sentence-viewer-tool-answers': ReaderSentenceViewerToolAnswers,
    'reader-sentence-viewer-tool-syntax': ReaderSentenceViewerToolSyntax,
    'reader-sentence-viewer-tool-satzhilfe': ReaderSentenceViewerToolSatzhilfe,
  },
  data: function () {
    return {
      'tools': [],
    }

  },
  props: {
    hasSteps: {
      type: Boolean,
      default: false
    },
    hasFarben: {
      type: Boolean,
      default: false
    },
    hasHighlights: {
      type: Boolean,
      default: false
    },
    hasSubjPred: {
      type: Boolean,
      default: false
    },
    hasAnswers: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    async unselectAll () {
      this.tools.forEach(async (toolcomponent) => {
        await (toolcomponent.active = false);
        toolcomponent.deactivate();
      });
    },
    select (clickedToolComponent) {
      this.tools.forEach(toolcomponent => {
        toolcomponent.active = (toolcomponent.name === clickedToolComponent.name);
        clickedToolComponent.activate();
      })
    },
    setInfobarText (string) {
      EventBus.$emit('setInfoBarText', string);
    },

  },

  mounted () {
    this.tools = Object.values(this.$refs);
    EventBus.$on('deactivateAllTools', () => {
      this.unselectAll();
    });

  }
}
</script>

<style>
#reader__workspace__analysis--sentence__toolbar, .reader__workspace__analysis--sentence__toolbar-container {
  transition: height 0.5s ease;
}


.reader__workspace__analysis--sentence__toolbar--item {

  background: #fff;
}

#reader__workspace__analysis--sentence__toolbar .reader-sentence-viewer_toolbar-button--active {
  background: #fff;
}
</style>